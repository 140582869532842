<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ readonly ? "Просмотр" : "Редактирование" }}
      </template>
      <a-form-model v-model="data" :model="model" :config="{ readonly, dense: true }" :errors="errors" @validate="validate($event)" @commit="onFormCommit($event)" />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="!readonly" @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, getForm, submitForm } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, getForm, submitForm],
  props: {
    dataTable: Array,
    id: Number,
    value: Boolean,
    RO: Array,
    type: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.documentOrderGoods,
      api: "",
      rowEdit: {},
      rows: [],
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.formDetail));
      model.forEach((el) => {
        if (this.RO.includes(el.name) && this.data[el.name] !== null) {
          el.readonly = true;
        }
        //скрыть если не по чеклисту
        if (this.type !== 1 && el.name == "price_plan") el.hidden = true;
        if (this.type == 1 && el.name == "amount_real") el.hidden = false;
        if (el?.sourceList) {
          //если список вариантов находится во внешнем массиве, присоединяю его
          el.options = this.m?.[el.sourceList]
            ? this.m[el.sourceList].filter((list) => {
                return !list?.hidden;
              })
            : [];
        }
      });
      return model;
    },
  },
  created() {
    this.updateData(this.id);
  },
  watch: {
    id(v) {
      if (v) this.row = this.dataTable.find((el) => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find((el) => el.id == this.id);
      }
    },
    "data.price_real"(v) {
      //равно если не по чеклисту
      if (this.type != 1 && v) this.data.price_plan = v;
      this.calcPrice(0);
    },
    "data.price_unit"(v) {
      if (!v) return;
      if (!this.loading || 1 == 11) this.calcPrice(1);
    },
    "data.amount_real"(v) {
      if (this.type !== 1 && v) this.data.amount = v;
      if (!this.loading || 1 == 11) this.calcPrice(1);
    },
    "data.amount"(v) {
      if (!v) return;
      if (this.type !== 1 && v) this.data.amount_real = v;
      if (!this.loading || 1 == 11) this.calcPrice(1);
    },
    "data.date_before"(v, old) {
      if (this.readonly && !this.loading && old) {
        if (!this.data.id) return;
      }
    },
  },
  methods: {
    async onFormCommit(e) {
      if (this.readonly) {
        console.log("commit", e);
        if (e.el.name == "date_before") {
          if (!this.data.id) return;
          if (!e.event) return;
          let q = `update accounting_doc_order_goods_table set date_before='${e.event}' where id=${this.data.id}`;
          await this.$axios.post("accounting/records/report-sql", { q });
          this.$emit("refresh");
        }
      }
    },
    afterFetchData() {
      this.calcPrice(0);
      this.loading = false;
    },
    resetForm() {},
    calcPrice(v) {
      let amount = this.data.amount_real || this.data.amount;
      if (v == 1) {
        if (this.data.price_unit && amount) this.data.price_real = this.data.price_unit * amount;
      } else if (this.data.price_real && amount) this.data.price_unit = this.data.price_real / amount;
      this.data.price_adding = 0;
    },
    async fetchData() {
      this.loading = true;
      this.rows = JSON.parse(JSON.stringify(this.dataTable));
      if (this.id) {
        this.rowEdit = this.rows.find((el) => el.id == this.id);
        for (const name in this.rowEdit) {
          this.$set(this.data, name, this.rowEdit[name]);
        }
      }
      this.afterFetchData();
    },
    beforeSave() {
      //if (this.data.status === null) this.data.status = 0;
    },
    post() {
      let rows = [];
      let date_before = this.data?.date_before;
      this.rows.forEach((r) => {
        if (r.id == this.id) {
          rows.push(this.data);
        } else {
          if (!r.date_before && date_before) r.date_before = date_before;
          rows.push(r);
        }
      });
      if (!this.id) {
        rows.push(this.data);
      }
      this.$emit("save", rows);

      this.$emit("input");
    },
  },
};
</script>
